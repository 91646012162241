import moment from 'moment';
import DateRangePicker  from "bootstrap-daterangepicker";

class DateUI {
  static initPickers() {
    DateUI.setDateTimePickers();
    DateUI.setDatePickers();
    return DateUI.setRangePickers();
  }

  static setDateTimePickers() {
    return $('.datetimepicker').datetimepicker({
      format: 'Y-m-d H:i',
      step: 30
    });
  }

  static setDatePickers() {
    $('.datepicker').datetimepicker({
      format: 'Y-m-d'});
    $('[data-toggle~=datetimepicker]').datetimepicker({
      format: 'Y-m-d H:i'
    });
    return $('[data-behaviour~=datepicker]').datetimepicker({
      format: 'Y-m-d'});
  }

  static setRangePickers(pickerClass) {
    let current_fiscal_year_end, current_fiscal_year_start, last_fiscal_year_end, last_fiscal_year_start;
    if (pickerClass == null) { pickerClass = '.rangePicker'; }
    if (moment().quarter() > 2) {
      current_fiscal_year_start = moment().month('July').startOf('month');
      current_fiscal_year_end = moment().add(1, 'year').month('June').endOf('month');
      last_fiscal_year_start = moment().subtract(1, 'year').month('July').startOf('month');
      last_fiscal_year_end = moment().month('June').endOf('month');
    } else {
      current_fiscal_year_start = moment().subtract(1, 'year').month('July').startOf('month');
      current_fiscal_year_end = moment().month('June').endOf('month');
      last_fiscal_year_start = moment().subtract(2, 'year').month('July').startOf('month');
      last_fiscal_year_end = moment().subtract(1, 'year').month('June').endOf('month');
    }

    return new DateRangePicker($(pickerClass).not('.rangePickerInitialized'), {
      locale: {
        format: 'YYYY-MM-DD'
      },
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'),
          moment().subtract(1, 'days')],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [moment().subtract(1, 'week').startOf('week'),
          moment().subtract(1, 'week').endOf('week')],
        'Past 13 Weeks': [moment().subtract(13, 'week').startOf('week'),
          moment().endOf('week')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')],
        'This FY': [current_fiscal_year_start, current_fiscal_year_end],
        'Last FY': [last_fiscal_year_start, last_fiscal_year_end],
        'This Year': [moment().startOf('year'), moment().endOf('year')],
        'Last Year': [moment().subtract(1, 'year').startOf('year'),
          moment().subtract(1, 'year').endOf('year')],
        'Past 12 months': [moment().subtract(1, 'year'), moment()],
        'Last 2 Years': [moment().subtract(2, 'year'), moment()]
      },
      startDate: moment().subtract(1, 'months'),
      endDate: moment()
    });
  }

  static setDates(startDate, endDate, pickerClass) {
    if (pickerClass == null) { pickerClass = '.rangePicker'; }
    return $(document).on('turbo:load', function() {
      if ($(pickerClass).length > 0) {
        let picker = DateUI.setRangePickers();
        picker.setStartDate(startDate);
        picker.setEndDate(endDate);
      }
    });
  }
}

window.DateUI = DateUI
export default DateUI