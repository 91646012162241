/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
UI = class UI {
  static initButtonSelector() {}
    // hi
  static setToggleButtons(group) {
    return $(group).attr("data-bs-toggle", "buttons");
  }
  static markSelected(form_field, selected) {
    return Array.from(selected).map((model) =>
      (function(model) {
        const button = $(form_field + "_" + model).parent("label");
        return button.addClass("active");
      })(model));
  }
};
