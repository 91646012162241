import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "tab"];

  changePane(event) {
    var cssClass = this.data.get("class");
    var urlId = event.target.dataset.tabOption;

    this.tabTargets.forEach((t) => t.classList.remove(cssClass));

    event.target.classList.toggle(cssClass);

    event.preventDefault()
    event.stopImmediatePropagation()

    this.contentTarget.innerHTML = this._spinner();

    fetch(this.data.get("url").replace(":id", urlId))
      .then(response => response.text())
      .then(html => this.contentTarget.innerHTML = html)

//     Rails.ajax({
//       url: this.data.get("url").replace(":id", urlId),
//       type: 'GET'
//     })
  }

  _beforeSend(){
    this.contentTarget.innerHTML = this._spinner();
  }

  _spinner(){
    return("<div class='d-flex justify-content-center'> \
      <div class='mt-3 spinner-border' role='status'> \
      <span class='visually-hidden'>Loading ...</span> \
      </div> \
      </div>");
  }
}