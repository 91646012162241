/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
StatusFilter = class StatusFilter {
  static init(parentTable) {
    StatusFilter.initializeButtons(parentTable);
    return StatusFilter.filterRows(parentTable);
  }

  static initializeButtons(parentTable) {
    $('#filter_show_inactive').show();
    $('#filter_hide_inactive').hide();

    $('#filter_show_inactive').on("click", function() {
      StatusFilter.toggleButtons();
      return StatusFilter.filterRows(parentTable, "show");
    });
    return $('#filter_hide_inactive').on("click", function() {
      StatusFilter.toggleButtons();
      return StatusFilter.filterRows(parentTable, "hide");
    });
  }

  static toggleButtons() {
    $('#filter_show_inactive').toggle();
    return $('#filter_hide_inactive').toggle();
  }

  static filterRows(parentTable, action) {
    if (action === "show") {
      return $(parentTable + ' tr.inactive').show();
    } else {
      return $(parentTable + ' tr.inactive').hide();
    }
  }
};


