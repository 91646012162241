/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
BlendElement = class BlendElement {
  constructor(coffee_line_id, coffee_line_name, percentage) {
    this.coffee_line_id = coffee_line_id;
    this.coffee_line_name = coffee_line_name;
    this.percentage = percentage;
  }

  static setBlendElements(blendElements) {
    BlendElement.clearBlendElements();
    localStorage.setItem('blends', JSON.stringify(blendElements));
    return $('#blend_blend_elements').val(JSON.stringify(blendElements));
  }

  static clearBlendElements() {
    localStorage.removeItem('blends');
    return $('#blend_blend_elements').val("");
  }

  static getBlendElements() {
    return JSON.parse(localStorage.getItem('blends'));
  }

  static findBlendElementIndex(coffee_line_id) {
    const blendElements = BlendElement.getBlendElements();
    const index = blendElements.findIndex(b => parseInt(b.coffee_line_id) === parseInt(coffee_line_id));
    return index;
  }

  static removeBlendElement(element) {
    const coffee_line_id = $(element).data("coffee_line_id").toString();
    const blendElementIndex = BlendElement.findBlendElementIndex(coffee_line_id);

    if(blendElementIndex > -1) {
      const blendElements = BlendElement.getBlendElements();
      blendElements.splice(blendElementIndex, 1);
      return BlendElement.setBlendElements(blendElements);
    }
  }

  static storeBlend(blendElement) {
    const blendElements = BlendElement.getBlendElements();
    blendElements.push(blendElement);
    return BlendElement.setBlendElements(blendElements);
  }
};


