/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Editor = class Editor {
  static init() {
    // document.addEventListener 'trix-initialize', (event) ->
    //   console.log "BOO"
    //   trix = event.target
    //   toolbar = trix.toolbarElement

    //   button = document.createElement("button")
    //   button.setAttribute("type", "button")
    //   button.setAttribute("class", "attach")
    //   button.setAttribute("data-trix-action", "x-attach")
    //   button.setAttribute("title", "Attach a file")
    //   button.setAttribute("tabindex", "-1")
    //   button.innerText = "Attach a File"

    //   uploadButton = toolbar.querySelector('.button_group.block_tools').appendChild(button)

    //   uploadButton.addEventListener 'click', ->
    //     console.log "Click"
    //     fileInput = document.createElement('input')
    //     fileInput.setAttribute("type", "file")
    //     fileInput.setAttribute("multiple", "")

    //     fileInput.addEventListener('change', (event) ->
    //       console.log "clicked"
    //       _ref = this.files
    //       _results = []

    //       _i = 0
    //       _len = _ref.length
    //       while _i < _len
    //         file = _ref[_i]
    //         _results.push(trix.editor.insertFile(file))
    //         _i++
    //       _results
    //     )
    //     fileInput.click()

    document.addEventListener('trix-attachment-add', function(event) {
      const {
        attachment
      } = event;
      if (attachment.file) {
        return Editor.uploadAttachment(attachment);
      }
    });

    return document.addEventListener('trix-attachment-remove', function(event) {
      const {
        attachment
      } = event;
      return Editor.deleteFile(attachment);
    });
  }

  static uploadAttachment(attachment) {
    const {
      file
    } = attachment;
    // key = createStorageKey(file)
    const form = new FormData;
    // form.append 'key', key
    form.append('Content-Type', file.type);
    form.append('upload[image]', file);
    const xhr = new XMLHttpRequest;
    xhr.open('POST', '/uploads', true);

    xhr.upload.onprogress = function(event) {
      let progress = undefined;
      progress = (event.loaded / event.total) * 100;
      return attachment.setUploadProgress(progress);
    };

    xhr.onload = function() {
      const response = JSON.parse(this.responseText);
      return attachment.setAttributes({
        url: response.url,
        image_id: response.image_id,
        href: response.url
      });
    };

    return xhr.send(form);
  }

  static deleteFile(n) {
    return $.ajax({
      type: 'DELETE',
      url: '/uploads/' + n.attachment.attributes.values.iamge_id,
      cache: false,
      contentType: false,
      processData: false
    });
  }
};
