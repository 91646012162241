import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["lead"]

  onQualifySuccess(event){
    let  [data, status, xhr] = event.detail
    this.leadTarget.remove()
    // Leads.updateColumnTotals()
  }
}