import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["categorySelector", "productSelector", "skuSelector",
    "grindOption", "roastOption"]
  static values = { skuField: String };

  getProducts(){
    this.clearDropdown(this.productSelectorTarget, "Select Product");
    this.clearDropdown(this.skuSelectorTarget, "");

    var categoryID = this.categorySelectorTarget.value;

    var categoryText =
      this.categorySelectorTarget.options[this.categorySelectorTarget.selectedIndex].text;

    this.displayCoffeeOptions(categoryText);

    fetch("/products/by_category/" + categoryID)
      .then(response => response.json())
      .then(json => this.populateProducts(json))
  }

  getSkus(){
    this.clearDropdown(this.skuSelectorTarget, "");
    var productID = this.productSelectorTarget.value;

    fetch("/skus/by_product/" + productID)
      .then(response => response.json())
      .then(json => this.populateSkus(json))
  }

  displayCoffeeOptions(categoryText){
    if (categoryText.match(/Coffee/i)) {
      this.grindOptionTarget.style.display = "initial";
      this.roastOptionTarget.style.display = "initial";
      this.grindOptionTarget.getElementsByTagName("select")[0].selectedIndex = 1;
      this.roastOptionTarget.getElementsByTagName("select")[0].selectedIndex = 1;
    } else {
      this.grindOptionTarget.style.display = "none";
      this.roastOptionTarget.style.display = "none";
      this.grindOptionTarget.getElementsByTagName("select")[0].selectedIndex = 0;
      this.roastOptionTarget.getElementsByTagName("select")[0].selectedIndex = 0;
    }
  }

  clearDropdown(dropdown, defaultText){
    if (dropdown.length > 0) {
      let i = 0;
      while (i < dropdown.length) {
        dropdown.remove(i)
      }
    }

    if (defaultText) {
      const option = document.createElement("option")
      option.innerHTML = defaultText;
      dropdown.appendChild(option);
    }
  }

  populateProducts(data){
    data.forEach((product) => {
      const productOption = document.createElement("option")
      productOption.value = product.id;
      productOption.innerHTML = product.name;
      this.productSelectorTarget.appendChild(productOption);
    })
  }

  populateSkus(data){
    data.forEach((sku) => {
      const skuOption = document.createElement("option")
      skuOption.value = sku[this.skuFieldValue];
      skuOption.innerHTML = sku.name;
      this.skuSelectorTarget.appendChild(skuOption);
    })
  }
}