/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

Tables = class Tables {
  static setDataTables(extraOptions) {
    if (extraOptions == null) { extraOptions = {}; }
    const options = {
      pagingType: 'bootstrap',
      retrieve: true,
      responsive: false,
      paging: false,
      info: true,
      dom: 'T<"clear">lfrtip',
      language: { search: "" },
    };

    return $('.dataTable').dataTable($.extend(options, extraOptions));
  }

  static setDataTableWithClass(cssClass, extraOptions) {
    if (extraOptions == null) { extraOptions = {}; }
    const options = {
      pagingType: 'bootstrap',
      retrieve: true,
      responsive: false,
      paging: false,
      info: true,
      dom: 'T<"clear">lfrtip',
      language: { search: "" },
    };
    return $(cssClass).dataTable($.extend(options, extraOptions));
  }

  static addDataTable(element, extraOptions ) {
    if (extraOptions == null) { extraOptions = {}; }
    const options = {
      lengthChange: false,
      lengthMenu: [ [25, 50, -1], [25, 50, "All"] ],
      language: {
        lengthMenu: "Display _MENU_"
      },
      responsive: false,
      dom: 'B<"float-end"f>Tlrtip',
      buttons: {
        buttons: ['copy', 'csv', 'excel'],
        container: {
          className: "dt-buttons btn-group btn-group-sm"
        }
      }
    };
    const table = $(element).DataTable($.extend(options, extraOptions));
    table.buttons().containers().appendTo(element + '.col-sm-6:eq(0)');
    table.page.len(-1).draw();
    $('.dt-buttons').addClass('btn-group-sm');
    $('.dt-buttons button').removeClass('btn-secondary');
    return $('.dt-buttons button').addClass('btn-outline-secondary');
  }

  static toggleStatus() {
    return $('.inactive').toggle();
  }

  static totalCallback( columnToTotal ) {
    return {
      'footerCallback'(row, data, start, end, display) {
        var data;
        const api = this.api();
        data = undefined;
        // Remove the formatting to get integer data for summation
        const intVal = function(i) {
          if (typeof i === 'string') { return i.replace(/[\$,]/g, '') * 1; } else if (typeof i === 'number') { return i; } else { return 0; }
        };

        // Total over all pages
        const total = api.column(columnToTotal).data().reduce(((a, b) => intVal(a) + intVal(b)), 0);
        // Total over this page
        const pageTotal = api.column(columnToTotal, {page: 'current'}).data().reduce(((a, b) => intVal(a) + intVal(b)), 0);
        // Update footer
        return $(api.column(columnToTotal).footer()).html('$' + pageTotal + ' ( $' + total + ' total)');
      }
    };
  }
};
