import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["bulkEditButton", "checkbox"]

  checkAll() {
    this.setAllCheckboxes(true);
  }

  checkNone() {
    this.setAllCheckboxes(false);
  }

  setAllCheckboxes(checked){
    this.checkboxTargets.
      forEach(target => target.checked = checked)
  }

  toggleAllCheckboxes() {
    if(this.checkboxTarget.checked){
      this.checkAll();
    } else {
      this.checkNone();
    }
    this.toggleButtons();
  }

  selectedCheckedboxes() {
    return this.checkboxTargets.filter((target) => target.checked)
  }

  toggleButtons() {
    this.bulkEditButtonTargets.
      forEach(target => target.disabled = !(this.selectedCheckedboxes().length > 0))
  }
}