/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
RoastingBlend = class RoastingBlend {
  static initialize() {
    return RoastingBlend.btnProfileSelector();
  }

  static btnProfileSelector() {
    return $('#profile_selector button').on("click", function() {
      $('#profile_selector button').removeClass('btn-primary');
      $('#roast_profile_id').val($(this).val());
      $(this).addClass('btn-primary');
      return RoastingBlend.updateBlendList();
    });
  }

  static buildOptions() {
    let options;
    return options = 
      {roast_profile_id: $('#roast_profile_id').val()};
  }

  static updateBlendList() {
    const options = RoastingBlend.buildOptions();
    Spinner.displaySpinner('#blending_list');
    return $.post('/roasting/blend/profile_change',
      { options },
      null,
      'script');
  }
};


