Blends = class Blends {
  static initBlendBuilder(blendElements) {
    if (blendElements == null) { blendElements = []; }
    BlendElement.clearBlendElements();
    $('#blend_line_percentage').on("click", function(event) {
      return $(this).select();
    });

    $('#add_blend_line').on("click", function(event){
      BlendRow.addRow();
      return event.preventDefault();
    });

    Blends.productSelector();
    Blends.locationSelector();
    BlendElement.setBlendElements(blendElements);
    return Blends.populateBuilder();
  }

  static populateBuilder() {
    for (let element of Array.from(BlendElement.getBlendElements())) {
      ((element => BlendRow.buildRow(element.coffee_line_id, element.coffee_line_name, element.percentage)))(element);
    }
    return Blends.updateBlendTotal();
  }

  static productSelector() {
    return $('#product_category_id').change(function() {
      let product_category_id = $('select#product_category_id :selected').val();
      if (product_category_id === '') {
        product_category_id = '0';
      }
      return Blends.getProductList(product_category_id);
    });
  }

  static getProductList(product_category_id) {
    $.ajax({
      url: '/products/by_category/' + product_category_id,
      method: 'GET',
      dataType: 'json',
      error(xhr, status, error) {
        console.error("AJAX ERROR: " + status + error);
      },
      success(response) {
        return Blends.buildProductSelector(response);
      }
    });
  }

  static buildProductSelector(response) {
    $('#blend_product_id').empty();
    $('#blend_product_id').append('<option>Select Product</option>');
    let i = 0;
    while (i < response.length) {
      $('#blend_product_id').append(Blends.productOption(response[i]));
      i++;
    }
  }

  static productOption(response) {
    return '<option value="' + response['id'] + '">' + response['name'] + '</option>';
  }


  static locationSelector() {
    return $('#blend_location_id').change(function() {
      const location_id = $('select#blend_location_id :selected').val();
      return BlendCoffeeLine.updateCoffeeLine(location_id);
    });
  }

  static blendTotal() {
    let blendTotal = 0;
    $('.blend-percentage').each(function() {
      return blendTotal += $(this).data('percentage');
    });
    return blendTotal;
  }

  static updateBlendTotal() {
    const blendTotal = Blends.blendTotal();
    $('#blend_total').text(blendTotal + '%');
    Blends.resetNewBlendRow(blendTotal);
    if (blendTotal >= 100) {
      $('#blend_total').removeClass('text-danger');
      $('#blend_total').addClass('text-success');
      return Blends.toggleFormStatus(true);
    } else {
      $('#blend_total').removeClass('text-success');
      $('#blend_total').addClass('text-danger');
      return Blends.toggleFormStatus(false);
    }
  }

  static validateNewBlendRowPercentage(percentage) {
    const blendTotal = parseInt(Blends.blendTotal());
    if ((blendTotal + parseInt(percentage)) > 100) {
      percentage = 100 - blendTotal;
    }
    return percentage;
  }

  static resetNewBlendRow(blendTotal) {
    return $('#blend_line_percentage').val(100 - blendTotal);
  }

  static toggleFormStatus(state) {
    $('#blend_line_percentage').prop("disabled", state);
    $('#coffee_line_id').prop("disabled", state);
    $('#add_blend_line').prop("disabled", state);
    const btnArray = $('.blend-builder :submit');
    return $(btnArray[btnArray.length - 1]).prop("disabled", !state);
  }
};

