/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
BlendRow = class BlendRow {
  static addRow() {
    const selectedLot = $('#coffee_line_id :selected');
    BlendRow.buildRow(selectedLot.val(),selectedLot.text(),
      $('#blend_line_percentage').val());
    return Blends.updateBlendTotal();
  }

  static buildRow(coffee_line_id, coffee_line_name, percentage) {
    percentage = Blends.validateNewBlendRowPercentage(percentage);
    let row = '<tr><td>' + coffee_line_name + '</td>';
    row += '<td class="blend-percentage text-end" ';
    row += 'data-percentage="' + percentage + '">' + percentage + '</td>';
    row += '<td class="text-end">';
    row += '<button class="btn btn-secondary delete_blend_row" ';
    row += 'data-coffee_line_id="' + coffee_line_id + '">-</button>';
    row += '</td></tr>';
    console.log(row);
    $('#blend_line_builder tr:last').before(row);

    if (BlendElement.findBlendElementIndex(coffee_line_id) === -1) {
      const blendElement = new BlendElement(coffee_line_id,
                                          coffee_line_name, percentage);
      BlendElement.storeBlend(blendElement);
    }

    return $('.delete_blend_row').on("click", function(event) {
      BlendRow.deleteRow($(this));
      return event.preventDefault();
    });
  }

  static deleteRow(element) {
    BlendElement.removeBlendElement(element);
    $(element).closest("tr").remove();
    return Blends.updateBlendTotal();
  }
};


