import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;

class Chart {
  constructor(el) {
    this.el = el;
  }

  render() {
    return this.el.highcharts({
      chart: {
        zoomType: 'x'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'datetime',
        maxZoom: 90 * 24 * 3600000
      }, //90 days
      yAxis:
        [{
          title: {
            text: 'Green Kgs',
            margin: 60
          },
          labels: {
            formatter() {
              return Highcharts.numberFormat(this.value, 0, ',');
            }
          }
        },{
          title: {
            text: 'Orders',
            margin: 50
          },
          labels: {
            formatter() {
              return Highcharts.numberFormat(this.value, 0, ',');
            }
          },
          opposite: true,
          min: 0
          }],
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal'
      },
      tooltip: {
        formatter() {
          return Highcharts.dateFormat("%B %Y", this.x) + ': ' + Highcharts.numberFormat(this.y, 0);
        }
      },
      plotOptions: {
        line: {
          lineWidth: 1,
          states: {
            hover: {
              lineWidth: 1
            }
          },
          marker: {
            enabled: false
          }
        }
      }
    });
  }
};
$(document).on("page:change", function() {
  const chart = new Chart($('#summary_chart'));
  return chart.render();
});