/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Cls = (Leads = class Leads {
  static initClass() {
    this.prototype.SELECTED_BUTTON = 'btn-outline-primary';
    this.prototype.UNSELECTED_BUTTON = 'btn-outline-secondary';
  }

  //###############
  // Locations
  //###############
  static filterLocations() {
    return $('#location_selector button').on("click", function() {
      Leads.toggleButton($(this));
      Leads.showLocations();
      return Leads.updateColumnTotals();
    });
  }

  static hideLocations() {
    return $('#filter_cards .sales_lead').hide();
  }

  static showLocations() {
    const selectedLocations = $("#location_selector .btn-outline-primary");
    if (selectedLocations.length === 0) {
      return $('#filter_cards .sales_lead').show();
    } else {
      Leads.hideLocations();
      return selectedLocations.each(function() {
        return $('.lead_location_' + $(this).val()).show();
      });
    }
  }

  //###############
  // Owners
  //###############
  static hideOwners() {
    return $('#filter_cards .sales_lead').hide();
  }

  static filterOwners() {
    return $('#owner_selector button').on("click", function() {
      Leads.toggleButton($(this));
      Leads.showOwners();
      return Leads.updateColumnTotals();
    });
  }

  static showCurrentOwner(ownerID) {
    const currentOwner = $('#owner_selector button[value=' + ownerID + ']');
    if (currentOwner.length === 1) {
      console.log(currentOwner);
      Leads.toggleButton(currentOwner);
    }
    Leads.showOwners();
    return Leads.updateColumnTotals();
  }

  static showOwners() {
    const selectedOwners = $('#owner_selector .btn-outline-primary');
    if (selectedOwners.length === 0) {
      return $('#filter_cards .sales_lead').show();
    } else {
      Leads.hideOwners();
      return selectedOwners.each(function() {
        return $('.lead_owner_' + $(this).val()).show();
      });
    }
  }

  //###############
  // Types
  //###############
  static filterTypes() {
    return $('#lead_type_selector button').on("click", function() {
      Leads.toggleButton($(this));
      Leads.showTypes();
      return Leads.updateColumnTotals();
    });
  }

  static hideTypes() {
    return $('#filter_cards .sales_lead').hide();
  }

  static showTypes() {
    const selectedTypes = $('#lead_type_selector .btn-outline-primary');
    if (selectedTypes.length === 0) {
      return $('#filter_cards .sales_lead').show();
    } else {
      Leads.hideTypes();
      return selectedTypes.each(function() {
        return $('.lead_type_' + $(this).val()).show();
      });
    }
  }

  //###############
  // Utilties
  //###############
  static showLeads() {
    Leads.showOwners();
    Leads.showLocations();
    return Leads.showTypes();
  }

  static toggleButton(button) {
    button.parent().children('button').removeClass('btn-outline-primary');
    button.parent().children('button').addClass('btn-outline-secondary');
    button.addClass('btn-outline-primary');
    return button.removeClass('btn-outline-secondary');
  }

  static updateColumnTotals() {
    Leads.calcTotals('leads_unqualified', 0);
    Leads.calcTotals('leads_qualified', 1);
    Leads.calcTotals('leads_on_hold', 1);
    return Leads.sortColumns();
  }

  static sortColumns() {
    $('#leads_qualified .leads').sortDivs();
    $('#leads_unqualified .leads').sortDivs();
    return $('#leads_on_hold .leads').sortDivs();
  }

  static calcTotals(column, displayTotals) {
    const cards = $('#' + column + " .sales_lead:visible");
    $('#' + column + '_count').text('[' + cards.length + ']');

    if (parseInt(displayTotals) === 1) {
      if (cards.length === 0) {
        Leads.updateColumnKg(column, "0");
        return Leads.updateColumnValue(column, "0");
      } else {
        const kgItems = Array.from(cards.map(function() { return $(this).data('kg'); }));
        const valueItems = Array.from(cards.map(function() { return parseFloat($(this).data('value')); }));

        const totalKg = kgItems.reduce((x, y) => x + y);
        const totalValue = valueItems.reduce((x, y) => x + y);

        Leads.updateColumnKg(column, totalKg);
        return Leads.updateColumnValue(column, totalValue);
      }
    }
  }

  static updateColumnKg(column, value) {
    return $('#' + column + '_kg').text(value);
  }

  static updateColumnValue(column, value) {
    let currency;
    if (parseFloat(value) === 0) {
      currency = "0";
    } else {
      currency = value.toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    }
    return $('#' + column + '_value').text('$' + currency);
  }

  static cardSorter() {
    return $('.card_sorter').on("click", function() {
      const column = $(this).closest(".card-list").attr("id");
      const direction = $('#' + column + '_direction');
      if (parseInt(direction.val()) === 1) {
        $('#' + column + ' .leads').sortDivs();
        return direction.val("0");
      } else {
        $('#' + column + ' .leads').sortDivs(0);
        return direction.val("1");
      }
    });
  }

  static liveFilter() {
    return $('#card_filter').on("keyup", function() {
      const filter = $(this).val();
      return $('.sales_lead').each(function() {
        if ($(this).text().search(new RegExp(filter, 'i')) < 0) {
          return $(this).fadeOut();
        } else {
          return $(this).show();
        }
      });
    });
  }
});
Cls.initClass();


