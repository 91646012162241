import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    console.log("hi")
  }

  foo(){
    console.log("this is the foo command")
  }
}