import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["controls", "errors", "form", "container", "groups"]

  static values = {
    panels: String,
    panelsurl: String,
    groupsurl: String,
    quote: Number,
    url: String
  }

  initialize(){
    // console.log(this.controlsTarget)
  }

  onPatchSuccess(event){
    let [data, status, xhr] = event.detail;

    this.containerTarget.outerHTML = xhr.response
    this.errorsTarget.innerText = ""
  }

  onPatchError(event){
    let [data, status, xhr] = event.detail;

    this.errorsTarget.innerHTML = xhr.response
  }

  updateControls(){
    fetch(this.urlValue.replace(":quote_id", this.quoteValue)).
      then(response => response.text()).
      then(html => {
        this.controlsTarget.outerHTML = html;
      })
  }

  updateTotals(){
    fetch(this.panelsurlValue.replace(":quote_id", this.quoteValue)).
      then(response => response.text()).
      then(html => {
        document.getElementById(this.panelsValue).outerHTML = html;
      })
  }

  updateGroups(){
    fetch(this.groupsurlValue.replace(":quote_id", this.quoteValue)).
      then(response => response.text()).
      then(html => {
        this.groupsTarget.outerHTML = html;
      })
  }
}