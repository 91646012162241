import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["container", "form", "errors", "results",
    "checkbox", "discountForm", "rancForm", "discount", "discountItemIds", "rancItemIds"]

  static values = { rancurl: String }

  initialize(){ }

  cancelSearch(event){
    this.formTarget.reset();
    this.resultsTarget.innerHTML = "";
    event.preventDefault()
  }

  onGetSuccess(event){
    let [data, status, xhr] = event.detail;
    event.srcElement.closest("tr").outerHTML = xhr.response;
  }

  onCancelSuccess(event){
    let [data, status, xhr] = event.detail;
    this.containerTarget.outerHTML = xhr.response
    event.preventDefault()
  }

  onPatchSuccess(event){
    let [data, status, xhr] = event.detail
    this.containerTarget.outerHTML = xhr.response
  }

  onPostSuccess(event){
    let [data, status, xhr] = event.detail;

    this.containerTarget.outerHTML = xhr.response;
  }

  onSearchSuccess(event){
    let [data, status, xhr] = event.detail;

    this.resultsTarget.innerHTML = xhr.response;
  }

  onDestroySuccess(event){
    let [data, status, xhr] = event.detail;

    this.containerTarget.outerHTML = xhr.response;
  }

  prepareDiscountForm(event){
    if(this.discountItemIdsTarget.value == ""){
      event.preventDefault()

      this.discountItemIdsTarget.value = this.getFormData()

      this.discountFormTarget.appendChild(this.discountItemIdsTarget)

      if(this.discountItemIdsTarget.value != ""){
        Rails.fire(this.discountFormTarget, "submit")
      }
    }
  }

  prepareRancForm(event){
    if (this.rancItemIdsTarget.value == ""){
      event.preventDefault()
      this.rancItemIdsTarget.value = this.getFormData()
      this.rancFormTarget.appendChild(this.rancItemIdsTarget)

      if(this.rancItemIdsTarget.value != ""){
        Rails.fire(this.rancFormTarget, "submit")
      }
    }
  }

  getFormData() {
    let Ids = []

    this.selectedCheckedboxes().
      forEach((target) => Ids.push(target.value))

    return Ids
  }

  selectedCheckedboxes() {
    return this.checkboxTargets.filter((target) => target.checked)
  }
}