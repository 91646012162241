/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Addresses = class Addresses {
  countrySelect() {
    const states = $('#address_state_id').html();
    // console.log(states)
    return $('#address_country_id').change(function() {
      const country = $('#address_country_id :selected').text();
      const escaped_country = country.replace(/([ #;&,.+*~\':"!^$[\]()=>|\/@])/g, '\\$1');
      const options = $(states).filter(`optgroup[label=${escaped_country}]`).html();
      // console.log(options)
      if (options) {
        $('#address_state_id').html(options);
        return $('#address_state_id').prop('disabled', false);
      } else {
        $('#address_state_id').empty();
        return $('#address_state_id').prop('disabled', 'disabled');
      }
    });
  }
};
