/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Roles = class Roles {
  static checkPermissions() {
    const user_roles = JSON.parse($.cookie("user_roles"));
    if (!user_roles) { return; }
    return Array.from(user_roles).map((role) =>
      $("." + role).show());
  }
};
