/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Lots = class Lots {
  static init() {
    return $('#filter .btn-primary').on("click", event => Spinner.displaySpinner('#filter_list'));
  }
};

$(document).on("page:change", function() {
  if (!($(".origins").length > 0)) { return; }
  return Lots.init();
});
