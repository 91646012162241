import { Turbo } from "@hotwired/turbo-rails"

require("@rails/activestorage").start()

import "trix"
import "@rails/actiontext"

require("@rails/ujs").start()

require("@rails/activestorage").start()
// require("jquery")
require("chartkick/highcharts")

// window.Rails = Rails
window.Turbo = Turbo

import "./src/**/*"

import LocalTime from "local-time"
LocalTime.start()

import "./channels"
import "./controllers"

import '@fortawesome/fontawesome-pro/js/all'
