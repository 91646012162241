/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Orders = class Orders {
  static selectAll() {
    return $('.selectAll').on('click', function() {
      return $('#orders input:checkbox').prop('checked', $(this).prop("checked"));
    });
  }
};
