/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Am = class Am {
  static totalVisits() {
    const valueCells = $('.visits');
    const valueVisits = Array.from(valueCells.map(function() { return parseInt($(this).data('visits')); }));
    const totalVisits = valueVisits.reduce((x, y) => x + y);
    return $('.total_visits').text(totalVisits);
  }
};
