import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  static targets = [
    'name',
    'address',
    'city',
    'streetNumber',
    'premise',
    'subpremise',
    'route',
    'postalCode',
    'county',
    'state',
    'country',
    'longitude',
    'latitude',
    'plusCode',
    'placeId',
    'isValid'
  ]

  static values = {
    country: Array
  }

  initialize() {
    this.placeChanged = this.placeChanged.bind(this)
  }

  connect() {
    if (typeof google !== 'undefined') {
      this.initAutocomplete()
    }
  }

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(this.addressTarget, this.autocompleteOptions)

    this.autocomplete.addListener('place_changed', this.placeChanged)
  }

  placeChanged() {
    this.reset()
    this.place = this.autocomplete.getPlace()
    console.log(this.place)
    const addressComponents = this.place.address_components

    if (addressComponents !== undefined) {
      const formattedAddress = this.formatAddressComponents(addressComponents)

      this.setAddressComponents(formattedAddress)
    }

    if (this.place.geometry !== undefined) {
      this.setGeometry(this.place.geometry)
    }

    this.setPlaceDetails(this.place)
  }

  reset(){
    if (this.hasNameTarget) this.nameTarget.value = ''
    if (this.hasStreetNumberTarget) this.streetNumberTarget.value = ''
    if (this.hasRouteTarget) this.routeTarget.value = ''
    if (this.hasPremiseTarget) this.premiseTarget.value = ''
    if (this.hasSubpremiseTarget) this.subpremiseTarget.value = ''
    if (this.hasCityTarget) this.cityTarget.value = ''
    if (this.hasCountyTarget) this.countyTarget.value = ''
    if (this.hasStateTarget) this.stateTarget.value = ''
    if (this.hasCountryTarget) this.countryTarget.value = ''
    if (this.hasPostalCodeTarget) this.postalCodeTarget.value = ''
    if (this.hasIsValidTarget) this.isValidTarget.value = ''
  }

  setAddressComponents(address) {
    if (this.hasStreetNumberTarget) this.streetNumberTarget.value = address.street_number || ''
    if (this.hasRouteTarget) this.routeTarget.value = address.route || ''
    if (this.hasPremiseTarget) this.premiseTarget.value = address.premise || ''
    if (this.hasSubpremiseTarget) this.subpremiseTarget.value = address.subpremise || ''
    if (this.hasCityTarget) this.cityTarget.value = address.locality || ''
    if (this.hasCountyTarget) this.countyTarget.value = address.administrative_area_level_2 || ''
    if (this.hasStateTarget) this.stateTarget.value = address.administrative_area_level_1 || ''
    if (this.hasCountryTarget) this.countryTarget.value = address.country || ''
    if (this.hasPostalCodeTarget) this.postalCodeTarget.value = address.postal_code || ''
    if (this.hasIsValidTarget) this.isValidTarget.value = address.is_valid
  }

  setPlaceDetails(place) {
    if (place.plus_code !== undefined) {
      if (this.hasPlusCodeTarget) this.plusCodeTarget.value = place.plus_code.global_code || ''
    }
    if (this.hasPlaceIdTarget) this.placeIdTarget.value = place.place_id || ''
    if (this.hasNameTarget) this.nameTarget.value = place.name
  }

  setGeometry(geometry) {
    if (this.hasLongitudeTarget) this.longitudeTarget.value = geometry.location.lng().toString()
    if (this.hasLatitudeTarget) this.latitudeTarget.value = geometry.location.lat().toString()
  }

  get autocompleteOptions() {
    return {
      fields: ['address_components', 'geometry', 'place_id', 'plus_code', 'name'],
      componentRestrictions: {
        country: this.countryValue
      }
    }
  }

  preventSubmit(event) {
    if (event.code === 'Enter') {
      event.preventDefault()
    }
  }

  formatAddressComponents(addressComponents) {
    const data = {}

    addressComponents.forEach((component) => {
      const type = component.types[0]

      data[type] = component.long_name
    })

    return data
  }
}