/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Checkbox = class Checkbox {
  static initToggles() {
    return $('input[type="checkbox"]').filter('[data-toggle="toggle"]').bootstrapToggle();
  }
};
