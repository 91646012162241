/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Spinner = class Spinner {
  static displaySpinner(element) {
    return $(element).html(`\
<div class='d-flex justify-content-center'> \
<div class='spinner-border' role='status'> \
<span class='visually-hidden'>Loading ...</span> \
</div> \
</div>`);
  }
  static tabSpinner(tab, element) {
    return $(tab + " a").on('click', () => Spinner.displaySpinner(element));
  }

  static formSpinner(form, element) {
    return $(form).on('submit', () => Spinner.displaySpinner(element));
  }

  static fadeFlash() {
    return $('#flash').delay(2000).fadeOut("slow");
  }

  static buildSlider(selector, options) {
    return $(selector).slider(options);
  }
};
