/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
BlendCoffeeLine = class BlendCoffeeLine {
  static updateCoffeeLine(location_id) {
    return $.ajax({
      url: '/coffee_lines/by_location/' + location_id,
      method: 'GET',
      dataType: 'json',
      error(xhr, status, error) {
        console.error("AJAX ERROR: " + status + error);
      },
      success(response) {
        return BlendCoffeeLine.buildCoffeeLineSelector(response);
      }
    });
  }

  static buildCoffeeLineSelector(response) {
    $('#coffee_line_id').empty();
    let i = 0;
    while (i < response.length) {
      $('#coffee_line_id').
        append(BlendCoffeeLine.coffeeLineOption(response[i]));
      i++;
    }
  }

  static coffeeLineOption(response) {
    return '<option value="' + response[1] + '">' + response[0] + '</option>';
  }
};
