/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
LotStocktakes = class LotStocktakes {
  static init() {
    $('#filter_toggle').on('click', () => $('#filter').show());
    return $('#filter_button').on('click', () => $('#filter_list').fadeTo('slow', 0.3));
  }
};

$(document).on("page:change", function() {
  if (!($(".lot_stocktakes").length > 0)) { return; }
  return LotStocktakes.init();
});
