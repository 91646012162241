/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Notifications = class Notifications {
  static watchSelectors() {
    $('#mobile_numbers').change(function() {
      const selected = $(this).find('option:selected').val();
      return $('#recipients_sms').val(selected);
    });
    return $('#email_addresses').change(function() {
      const selected = $(this).find('option:selected').val();
      return $('#recipients_email').val(selected);
    });
  }
};
