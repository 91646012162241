import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["taskTemplateItemSelector", "userSelector"]

  getUsers() {
    console.log("Get Users");
    this.clearDropdown(this.userSelectorTarget, "");
    var taskTemplateItemId = this.taskTemplateItemSelectorTarget.value;

    fetch("/setup/task_template_items/users?id=" + taskTemplateItemId)
      .then(response => response.json())
      .then(json => this.populateTaskUsers(json))
  }

  populateTaskUsers(data){
    data.forEach((user) => {
      const userOption = document.createElement("option")
      userOption.value = user.id;
      userOption.innerHTML = user.fullname
      this.userSelectorTarget.appendChild(userOption)
    })
  }

  clearDropdown(dropdown, defaultText){
    if (dropdown.length > 0) {
      let i = 0;
      while (i < dropdown.length) {
        dropdown.remove(i)
      }
    }

    if (defaultText) {
      const option = document.createElement("option")
      option.innerHTML = defaultText;
      dropdown.appendChild(option);
    }
  }
}