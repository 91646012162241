import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "commentForm", "commentList" ]

  static values = {
    commentableUrl: String,
    commentableType: String,
    commentableId: Number,
    userId: Number
  }

  newComment() {
    let [data, status, xhr] = event.detail;
    this.commentFormTarget.innerHTML = xhr.response;
    this._showModal();
  }

  onCreateSuccess(event) {
    let [data, status, xhr] = event.detail;

    this.commentListTarget.innerHTML = xhr.response;

    this._hideModal();
  }

  editComment() {
    let [data, status, xhr] = event.detail;
    this.commentFormTarget.innerHTML = xhr.response;
    this._showModal();
  }

  onUpdateSuccess(){
    let [data, status, xhr] = event.detail;
    this.commentListTarget.innerHTML = xhr.response;
    this._hideModal();
  }

  onDeleteSuccess(){
    let [data, status, xhr] = event.detail
    this.commentListTarget.innerHTML = xhr.response;
  }

  _showModal(){
    new Modal(this.commentFormTarget).show();
  }

  _hideModal(){
    Modal.getInstance(this.commentFormTarget).hide();
  }
}