/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Esf = class Esf {
  show_alert(message) {
    return alert(message);
  }

  static search_results_spinner() {
    return Spinner.displaySpinner("#search-results");
  }

  static enable_item_update() {
    return $('#esf_equipment_items_update_form .btn').prop("disabled", false);
  }

  static disable_item_update() {
    return $('#esf_equipment_items_update_form .btn').prop("disabled", true);
  }

  static handle_item_checkboxes() {
    return $('#esf_equipment_items_update_form .item_checkbox').on("change", function() {
      if ($('#esf_equipment_items_update_form input:checked').length === 0) {
        return Esf.disable_item_update();
      } else {
        return Esf.enable_item_update();
      }
    });
  }

  static toggle_all_checkboxes() {
    return $('#esf_equipment_items_update_form .select_all').on("change", function() {
      const status = this.checked;
      $('#esf_equipment_items_update_form .item_checkbox').each((i, elem) => {
        elem.checked = status;
      });
      if (status) {
        return Esf.enable_item_update();
      } else {
        return Esf.disable_item_update();
      }
    });
  }

  // static selectAllItems() {
  //   return $('.selectAll').on('click', function() {
  //     return $('#esf-equipment-items input:checkbox').prop('checked', $(this).prop("checked"));
  //   });
  // }

  static toggleItemEditButtons() {
    return $('#esf-equipment-items input:checkbox').on('click', function() {
      const count = $('#esf-equipment-items input:checkbox:checked').length;
      if (count === 0) {
        return $('#item-edit-buttons .btn').addClass('disabled');
      } else {
        return $('#item-edit-buttons .btn').removeClass('disabled');
      }
    });
  }
};


$(document).on("submit", "#sales_esf_esf_equipment_items_search", () => Esf.search_results_spinner());
