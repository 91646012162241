/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
Company = class Company {
  static init() {}

  static getMap(opts) {
    let src = 'http://maps.googleapis.com/maps/api/staticmap?';
    const params = $.extend({
      center: 'New York, NY',
      zoom: 14,
      size: '512x512',
      maptype: 'roadmap',
      sensor: false
    }, opts);
    const query = [];
    $.each(params, function(k, v) {
      query.push(k + '=' + encodeURIComponent(v));
    });
    src += query.join('&');
    return '<img src="' + src + '" />';
  }

  // showPopover: (element, address) ->
  //   content = getMap(address)
  //   $(element).popover({content: content})
  //
  // static showPopover(element) {
  //   const content = Company.getMap({center: "253 Jersey St, Wembley, WA, Australia"});
  //   return $(element).popover({trigger: 'hover', content, html: true});
  // }


  static bindParentBilling() {
    return $('#company_parent_id').on("change", function() {
      const selected = $('#company_parent_id').find(':selected').val();
      return Company.setParentBilling(selected);
    });
  }

  static setParentBilling(value) {
    console.log(value);
    if (value.length === 0) {
      return Company.hideParentBilling();
    } else {
      return Company.showParentBilling();
    }
  }

  static hideParentBilling() {
    return $('#parent-billing').hide();
  }

  static showParentBilling() {
    return $('#parent-billing').show();
  }
};

$(document).on("page:change", function() {
  if (!($(".company").length > 0)) { return; }
  return Company.init();
});
