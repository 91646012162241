/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
RoastingList = class RoastingList {
  static handleButtons() {
    $('#btn_build_roasting_list').on('click', () => RoastingList.buildRoastingChit());
    $('#btn_chit_print').on('click', () => window.print());
    return $('#profile_selector button').on('click', function() {
      Spinner.displaySpinner('#coffee_lines');
      return RoastingList.changeRoastProfile(this);
    });
  }

  static changeRoastProfile(button) {
    $('#profile_selector button').removeClass('btn-primary');
    $('#roast_profile_id').val($(button).val());
    $(button).addClass('btn-primary');
    return $.post('/roasting/list/profile_change',
      { roast_profile_id: $('#roast_profile_id').val() },
      null,
      'script');
  }

  static setStatusSelector() {
    return $(".status_roasting_list_lot").each(function() {
      $(this).removeClass('positive');
      $(this).removeClass('negative');
      return $(this).addClass(parseFloat($(this).text()) >= 0 ? "positive" : "negative" );
    });
  }

  static filterOriginCategories() {
    return $('#lot_category_selector button').on("click", function() {
      const selectedButton = $(this);
      if (selectedButton.hasClass('btn-primary')) {
        selectedButton.removeClass('btn-primary');
      } else {
        selectedButton.addClass('btn-primary');
      }
      return RoastingList.showOriginCategories();
    });
  }

  static hideOriginCategories() {
    return $('#roasting_list .roasting_list_lot').hide();
  }

  static showOriginCategories() {
    const selectedCategories = $("#lot_category_selector .btn-primary");
    if (selectedCategories.length === 0) {
      return $('#roasting_list .roasting_list_lot').show();
    } else {
      RoastingList.hideOriginCategories();
      return $('#lot_category_selector .btn-primary').each(function() {
        return $('.lot_category_' + $(this).val()).show();
      });
    }
  }

  static handleKeypress() {
    return $('.roasting_quantity').keypress(function(e) {
      const c = e.which ? e.which : e.keyCode;
      const roastAmount = parseFloat($(this).val() + String.fromCharCode(c));
      return RoastingList.updateNetStatus($(this).parents('.roasting_list_lot'), roastAmount);
    });
  }

  static calculateMoistureLoss(roasting_amount, moisture_loss) {
    return roasting_amount - (roasting_amount * (moisture_loss * 0.01));
  }

  static updateNetStatus(row, value) {
    const currentStatus = parseFloat(row.find('.status_roasting_list_lot').text());
    const moistureLoss = row.find('.moisture_loss_roasting_list_lot').val();
    const updatedNet = currentStatus + RoastingList.calculateMoistureLoss(value, moistureLoss);

    return row.find('.net_roasting_list_lot').
      text(updatedNet.toFixed(1));
  }


  static buildRoastingChit() {
    const RoastingListLots = [];
    $('.roasting_quantity').each(function() {
      if (!isNaN(parseFloat($(this).val()))) {
        const origin_quantity = {
          id: $(this).attr("id").replace(/quantity_/, ""),
          quantity: $(this).val()
        };
        return RoastingListLots.push(origin_quantity);
      }
    });
    console.log(RoastingListLots);
    return $.ajax('/roasting/list/build_roasting_chit', {
      type: 'POST',
      data: {
        roasting_list_lots: JSON.stringify(RoastingListLots),
        roast_profile_id: $('#roast_profile_id').val()
      },
      dataType: 'json',
      error(jqXHR, textStatus, errorThrown) {
        return alert( 'Something went wrong: ' + textStatus + errorThrown );
      },
      success(data ,textStatus, jqXHR) {
        // RoastingList.hideOrigins()
        RoastingList.showRoastingChit();
        return RoastingList.createRoastingChit(data);
      }
    }
    );
  }

  static hideOrigins() {
    $('#roasting_predictor').hide();
    $('#origin_filter').hide();
    return $('#roasting_list').hide();
  }

  static showRoastingChit() {
    $('#roasting_chit').show();
    return $('#roasting_chit').removeClass('hidden');
  }

  static showRoastingList() {
    $('#roasting_chit').hide();
    $('#roasting_predictor').show();
    $('#origin_filter').show();
    return $('#roasting_list').show();
  }

  static createRoastingChit(data) {
    $('#roasting_chit__data').val(JSON.stringify(data));
    return Array.from(data).map((rlo) =>
      RoastingList.appendChitRow(rlo));
  }

  static appendChitRow(rlo) {
    const quantity = parseFloat(rlo.quantity);
    const moisture_loss_figure = parseFloat(rlo.moisture_loss_percentage);
    const quantity_brown = RoastingList.calculateMoistureLoss(quantity, moisture_loss_figure);

    const status = (parseFloat(rlo.amount) - parseFloat(rlo.ordered)) + quantity_brown;

    return $('#roasting_chit #chits table tbody').append(`\
<tr class="roasting_list_lot"> \
<td>` + rlo.name + `</td> \
<td>` + rlo.roast_profile_name + `</td> \
<td class="text-end">` + quantity.toFixed(1) + `</td> \
<td class="text-end">` + status.toFixed(1) + `</td> \
</tr>`);
  }

  static buildPredictorOptions(row) {
    let options;
    return options = {
      product_id: row.find('#roasting_predictor_product_id').val(),
      roast_profile_id: $('#roast_profile_id').val(),
      location_id: $('#location_id').val(),
      quantity: row.find('#roasting_predictor_quantity').val()
    };
  }

  static getPredictorComponents() {
    return $('.roasting_predictor_row .btn').on("click", function() {
      const predictorRow = $(this).parents('.roasting_predictor_row');
      const predictorOptions = RoastingList.buildPredictorOptions(predictorRow);
      return $.ajax('/roasting/list/predictor_components', {
        type: 'POST',
        data: { roast_list_predictor: predictorOptions },
        dataType: 'json',
        error(jqXHR, textStatus, errorThrown) {
          return alert('something went wrong: ' + textStatus);
        },
        success(data ,textStatus, jqHXR) {
          predictorRow.find("#roasting_predictor_data").val(JSON.stringify(data));
          RoastingList.updateOrigins(data);
          RoastingList.setStatusSelector();
          return RoastingList.addProductToPredictorList($('#roasting_predictor_product_id option:selected').text(), $('#roasting_predictor_quantity').val());
        }
      }
      );
    });
  }

  static updateOrigin(roasting_list_lot_id, quantity) {
    const row = $('#roasting_list_lot_' + roasting_list_lot_id);
    const ordered = $('#ordered_roasting_list_lot_' + roasting_list_lot_id);
    const status = $('#status_roasting_list_lot_' + roasting_list_lot_id);

    const updatedQuantity = parseFloat(ordered.text()) + parseFloat(quantity);
    const updatedStatus = parseFloat(status.text()) - parseFloat(quantity);

    ordered.text(updatedQuantity.toFixed(1));
    status.text(updatedStatus.toFixed(1));
    ordered.addClass('predicted');
    return status.addClass('predicted');
  }
    // row.effect("highlight", {}, 3000)

  static updateOrigins(data) {
    return Array.from(data).map((origin_data) =>
      RoastingList.updateOrigin(origin_data.roasting_list_lot_id,
        origin_data.quantity));
  }

  static inputSelectAllOnClick(selector) {
    return $(selector).on("click", function() {
      return $(this).select();
    });
  }

  static addProductToPredictorList(productName, quantity) {
    $('#predictor_list').removeClass('invisible');
    return $('#predictor_list').append(`\
<div class='row'><div class='col-sm-6'>` + productName +
        "</div><div class='col-sm-4 numeric'>" + quantity +
        "</div></div>");
  }
};

