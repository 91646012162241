import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  click(event){
    this.spin()
  }

  spin(){
    document.getElementById(this.data.get("target")).innerHTML = this.spinner()
  }

  imagePath(){
    return this.data.get("image")
  }

  spinner(){
    return("<div class='d-flex justify-content-center'> \
      <div class='mt-3 spinner-border' role='status'> \
      <span class='visually-hidden'>Loading ...</span> \
      </div> \
      </div>");
  }
}