window.initMap = function(...args){
  const event = document.createEvent("Events")
  event.initEvent("google-maps-callback", true, true)
  event.args = args
  window.dispatchEvent(event)
}

window.initAutocomplete = function(){
  const event = new Event('google-maps-callback', { bubbles: true, cancelable: true })
  window.dispatchEvent((event))
}