import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["quote", "errors", "form", "container"]
  static values = {
    list: String,
    sellprice: Number,
    position: Number,
    totalpanel: String
  }

  initialize(){
  }

  onPostSuccess(event){
    let [data, status, xhr] = event.detail

    document.
      getElementById(this.listValue).
      insertAdjacentHTML('beforeend', xhr.response);
    this.formTarget.reset();
  }

  onPatchSuccess(event){
    let [data, status, xhr] = event.detail;

    this.containerTarget.outerHTML = xhr.response
    this.errorsTarget.innerText = ""
  }

  onPatchError(event){
    let [data, status, xhr] = event.detail;

    this.errorsTarget.innerHTML = xhr.response
  }
}