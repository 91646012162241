// Defines the Nav class, which handles displaying and hiding the sidebar accordions
// as well as various tabs

export default class Nav {
  static initSidebar() {
    const sidebar = $('[data-toggle=offcanvas]');
    sidebar.off('click');
    return sidebar.on('click', () => $('.row-offcanvas').toggleClass('active'));
  }

  static toggleSidebar() {
    const li = $('#sidebar .list-group-panel #acc a[href="' + $(location).attr("pathname") + '"]');
    const accordion = li.parents('.accordion-body')[0];
    if (!(accordion == null)) { return $('#' + accordion.id).addClass("in"); }
  }

  static showOrders() {
    return Nav.toggleSidebarByID('#acc-orders');
  }
  static showCompanies() {
    return Nav.toggleSidebarByID('#acc-companies');
  }
  static showSales() {
    return Nav.toggleSidebarByID('#acc-sales');
  }
  static showAM() {
    return Nav.toggleSidebarByID('#acc-am');
  }
  static showService() {
    return Nav.toggleSidebarByID('#acc-service');
  }
  static showProducts() {
    return Nav.toggleSidebarByID('#acc-products');
  }
  static showCoffee() {
    return Nav.toggleSidebarByID('#acc-origins');
  }
  static showRoasting() {
    return Nav.toggleSidebarByID('#acc-roasting');
  }
  static showStats() {
    return Nav.toggleSidebarByID('#acc-stats');
  }
  static showReports() {
    return Nav.toggleSidebarByID('#acc-reports');
  }
  static showSetup() {
    return Nav.toggleSidebarByID('#acc-setup');
  }
  static showAdmin() {
    return Nav.toggleSidebarByID('#acc-admin');
  }
  static showFinances() {
    return Nav.toggleSidebarByID("#acc-finances");
  }

  static toggleSidebarByID(accordion) {
    document.querySelector(accordion).classList.add("show");
    document.querySelector(accordion).
      parentElement.
      querySelector('.accordion-button').
      classList.remove("collapsed")
  }

  static setActiveTab(parent, element) {
    $(parent).removeClass("active");
    return $(element).addClass("active");
  }

  static navTabs() {
    return $('#tabs li a').on('click', function() {
      const link = $(this);
      $('#tabs li').removeClass("active");
      $('#summary table tbody tr').fadeTo('slow', 0.3);
      return link.parent().addClass("active");
    });
  }

  static setHomeTab(tab, css_class) {
    return $(tab + " a").addClass(css_class);
  }

  static setActiveSearchTab(parent) {
    const tabsWithResults = $(parent + " .badge").parent();
    if (tabsWithResults.length > 0) {
      console.log("foo")
      return tabsWithResults.first().tab('show');
    }
  }
};

window.Nav = Nav;