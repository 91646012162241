/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
LeadClosures = class LeadClosures {
  static setForm() {
    if (!$("#lead_closure_reactivate").checked) {
      $(".lead_closure_reactivate_at").hide();
    }
    if (!$("#lead_closure_avoidable").checked) {
      $(".lead_closure_avoidable_reason").hide();
      return $('.avoidable').hide();
    }
  }
  static setToggles() {
    $('#lead_closure_reactivate').on('change', function() {
      if ($(this).is(":checked")) {
        return $('.lead_closure_reactivate_at').show();
      } else {
        return $('.lead_closure_reactivate_at').hide();
      }
    });
    return $('#lead_closure_avoidable').on('change', function() {
      if ($(this).is(":checked")) {
        $('.lead_closure_avoidable_reason').show();
        return $('.avoidable').show();
      } else {
        $('.lead_closure_avoidable_reason').hide();
        return $('.avoidable').hide();
      }
    });
  }
  static setSortable() {
    return sortable('.sortable', {
      items: 'tr.sortable-tr',
      placeholder: "<tr><td colspan='6'>Drop Row Here</td></tr>",
      sortupdate(e, ui) {
        return console.log('hi');
      }
    }
    );
  }
};
    // $('.sortable').sortable().bind 'sortupdate', (e, ui) ->
    //   # array to store new order
    //   updated_order = []
    //   # set the updated positions
    //   # set_positions()
    //   # populate the updated_order array with the new task positions
    //   $('tr.sortable-tr').each (i) ->
    //     updated_order.push
    //       id: $(this).data('id')
    //       position: i + 1
    //     return
    //   # send the updated order via ajax
    //   alert(updated_order)
    //   # $.ajax
    //   #   type: 'PUT'
    //   #   url: '/tasks/sort'
    //   #   data: order: updated_order
    //   # return
    // return

