import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["hide"]
  static targets = ["content"]

  connect(){
    // console.log("Hi");
  }

  toggle(){
    this.contentTargets.forEach((t) => t.classList.toggle(this.hideClass));
  }
}