/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
EsfFinance = class EsfFinance {
  static options_bind() {
    $('#esf_finance_bill_to').on("change", function() {
      EsfFinance.hide_options();
      const selected = $('#esf_finance_bill_to option').filter(':selected').val();
      return EsfFinance.display_selected_option(parseInt(selected));
    });
    return $('#esf_finance_billing_payment_option').on("change", function() {
      EsfFinance.hide_payment_options();
      const selected = $('#esf_finance_billing_payment_option option').filter(':selected').val();
      return EsfFinance.display_payment_option(parseInt(selected));
    });
  }

  static select_billing_option(value) {
    return $('#esf_finance_billing_payment_option').find('option:contains(' + value + ')').prop('selected', true);
  }

  static display_selected_option(selected) {
    switch (selected) {
      case 1: return EsfFinance.show_customer();
      case 2: return EsfFinance.show_finance();
      case 3: return EsfFinance.show_other();
    }
  }

  static display_payment_option(selected) {
    switch (selected) {
      case 3: return EsfFinance.show_payment_options();
    }
  }

  static options_display(selected) {
    EsfFinance.hide_options();
    return EsfFinance.display_selected_option(selected);
  }

  static payment_options_display(selected) {
    EsfFinance.hide_payment_options();
    return EsfFinance.display_payment_option(selected);
  }

  static hide_options() {
    $(".esf_finance_bill_to_other").hide();
    $('.esf_finance_finance_schedule').hide();
    $('.esf_finance_finance_schedule_at').hide();
    $('.esf_finance_billing_other_contact').hide();
    $('.esf_finance_billing_other_email').hide();
    $('.esf_finance_equipment_schedule').hide();
    $('.esf_finance_equipment_schedule_sent').hide();
    $('.esf_finance_equipment_schedule_sent_at').hide();
    $('.esf_finance_equipment_schedule_chased').hide();
    $('.esf_finance_equipment_schedule_chased_at').hide();
    $('.esf_finance_purchase_order').hide();
    $('.esf_finance_purchase_order_received').hide();
    $('.esf_finance_purchase_order_received_at').hide();
    return $('.esf_finance_purchase_order_number').hide();
  }


  static hide_payment_options() {
    return $('.esf_finance_billing_payment_other').hide();
  }

  static show_payment_options() {
    return $('.esf_finance_billing_payment_other').show();
  }

  static show_ranc() {
    $('.esf_finance_ranc_journal').show();
    $('.esf_finance_ranc_journal_posted').show();
    return $('.esf_finance_ranc_journal_posted_at').show();
  }

  static hide_ranc() {
    $('.esf_finance_ranc_journal').hide();
    $('.esf_finance_ranc_journal_posted').hide();
    return $('.esf_finance_ranc_journal_posted_at').hide();
  }

  static show_other() {
    EsfFinance.select_billing_option('50');
    $(".esf_finance_bill_to_other").show();
    $(".esf_finance_billing_other_contact").show();
    return $(".esf_finance_billing_other_email").show();
  }

  static show_finance() {
    EsfFinance.select_billing_option('100');
    $('.esf_finance_finance_schedule').show();
    $('.esf_finance_finance_schedule_at').show();
    $('.esf_finance_equipment_schedule').show();
    $('.esf_finance_equipment_schedule_sent').show();
    $('.esf_finance_equipment_schedule_sent_at').show();
    $('.esf_finance_equipment_schedule_chased').show();
    $('.esf_finance_equipment_schedule_chased_at').show();
    $('.esf_finance_purchase_order').show();
    $('.esf_finance_purchase_order_received').show();
    $('.esf_finance_purchase_order_received_at').show();
    return $('.esf_finance_purchase_order_number').show();
  }

  static show_customer() {
    return EsfFinance.select_billing_option('50');
  }

  static display_ranc(status) {
    if (status) {
      return EsfFinance.show_ranc();
    } else {
      return EsfFinance.hide_ranc();
    }
  }
};


