import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radio", "hidden" ]

  connect() {
    console.log("hi")
  }

  setAll(checked) {
    this.radioTargets.
    forEach(target => target.checked = checked)
  }

  logValue() {
    this.hiddenTarget.value = this.selectedCheckBox()[0].value;
  }

  selectedCheckBox() {
    return this.radioTargets.filter((target => target.checked))
  }
}