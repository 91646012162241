import JQuery from 'jquery';
window.$ = window.JQuery = JQuery;
import DataTable from "datatables.net"
require('datatables.net-buttons')(DataTable)
require('datatables.net-buttons-bs5')(DataTable)

// init = function() {
  // console.log("init()")
  // // DateUI.initPickers()
  // // Tables.setDataTables()
  // .navTabs();
  // Checkbox.initToggles();

  // $('.submit').on("click", event => $('#filter').fadeOut("slow", 0.2, Spinner.displaySpinner("#filter")));
  // $('[data-toggle="tooltip"]').tooltip();

  // $('#recent_tabs li a').on('click', function() {
  //   $('#recent_tabs li').removeClass("active");
  //   $(this).parent().addClass("active");
  //   return $('#recent_list').fadeOut("slow");
  // });

  // $('#roasting_list #sub-header .page-actions a').on('click', () => $('#roasting_list').fadeTo('slow', 0.3));

  // return $('.dropdown-toggle').dropdown();
// };


// DataTable sorting extensions for Kg/Wk
$.extend($.fn.dataTableExt.oSort, {
  'num-weekly-pre'(x) {
    x = x.replace(/\D+/g, '');
    return parseFloat(x);
  },
  'num-weekly-asc'(x, y) {
    if (x < y) { return -1; } else if (x > y) { return 1; } else { return 0; }
  },
  'num-weekly-desc'(x, y) {
    if (x < y) { return 1; } else if (x > y) { return -1; } else { return 0; }
  }
}
);

$.fn.sortDivs = function(direction) {
  if (direction == null) { direction = 1; }
  const dec_sort = function(a, b) {
    if ($(b).data('sort') < $(a).data('sort')) { return 1; } else { return -1; }
  };
  const inc_sort = function(a, b) {
    if ($(b).data('sort') > $(a).data('sort')) { return 1; } else { return -1; }
  };

  if (direction === 1) {
    $('> div', this[0]).sort(dec_sort).appendTo(this[0]);
  } else {
    $('> div', this[0]).sort(inc_sort).appendTo(this[0]);
  }
};