/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
RoastingOrders = class RoastingOrders {
  static initialize() {
    RoastingOrders.btnProfileSelector();
    RoastingOrders.btnDetailSelector();
    return RoastingOrders.btnDaySelector();
  }

  static btnDaySelector() {
    return $('#day_selector button').on("click", function() {
      $('#day_selector button').removeClass('btn-primary');
      $('#day_selector_value').val($(this).data("date"));
      $(this).addClass('btn-primary');
      return RoastingOrders.updateOrderSummary();
    });
  }

  static btnProfileSelector() {
    return $('#profile_selector button').on("click", function() {
      $('#profile_selector button').removeClass('btn-primary text-white');
      $('#roast_profile_id').val($(this).val());
      $(this).addClass('btn-primary text-white');
      return RoastingOrders.updateOrderSummary();
    });
  }

  static btnDetailSelector() {
    return $('#detail_selector button'). on("click", function() {
      $('#detail_selector button').removeClass('btn-primary text-white');
      $('#display_type').val($(this).val());
      $(this).addClass('btn-primary text-white');
      return RoastingOrders.updateOrderSummary();
    });
  }

  static updateOrderSummary() {
    const options = RoastingOrders.buildOptions();
    Spinner.displaySpinner('#product_summary');
    Spinner.displaySpinner('#lot_summary');
    return $.post('/roasting/orders/selector_change',
      { options },
      null,
      'script');
  }

  static buildOptions() {
    let options;
    return options = {
      day_selector_value: $('#day_selector_value').val(),
      roast_profile_id: $('#roast_profile_id').val(),
      display_type: $('#display_type').val()
    };
  }

  static buildOrders() {
    let orders;
    const ids = [];
    $('#orders_summary :checked').each(function() {
      return ids.push($(this).attr("value"));
    });
    return orders =
      {order_ids: ids};
  }


  static transferOrders() {
    const options = RoastingOrders.buildOptions();
    Spinner.displaySpinner('#product_summary');
    Spinner.displaySpinner('#lot_summary');
    return $.post('/roasting/orders/transfer_all',
      { options },
      null,
      'script');
  }

  static transferSelectedOrders() {
    const options = RoastingOrders.buildOptions();
    const orders = RoastingOrders.buildOrders();
    Spinner.displaySpinner('#order_summary');
    Spinner.displaySpinner('#lot_summary');
    return $.post('/roasting/orders/transfer_selected',
      { options, orders },
      null,
      'script');
  }

  static displaySelectedOrderOriginSummary() {
    const orders = RoastingOrders.buildOrders();
    const options = RoastingOrders.buildOptions();
    return $.post('/roasting/orders/lot_summary',
      { options, orders },
      null,
      'script');
  }

  static inputSelectAllOnClick(selector) {
    return $(selector).on("click", function() {
      return $(this).select();
    });
  }
};
