/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
CoffeeLinesDeactivator = class CoffeeLinesDeactivator {
  static displayBlends(old_line_id, new_line_id) {
    return $.get('/coffee_lines/' + old_line_id + '/deactivator?new_line_id=' + new_line_id, {}, (function(data) {
      $('#blends_list').html(data);
    }), 'html');
  }

  static initCoffeeLineSelector() {
    return $('#new_line_id').change(function() {
      const new_line_id = $('select#new_line_id :selected').val();
      return $('#deactivate_submit').attr("disabled", new_line_id === '');
    });
  }
};
