/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
RoastingRoasts = class RoastingRoasts {
  static roastQuantityFocus() {
    return $('.roasting_quantity').on('click', function() {
      return $(this).select();
    });
  }
  static fadeRow() {
    return $('.row .origin .btn').on('click', function() {
      return $(this).parent().parent().fadeTo('slow', 0.3);
    });
  }
};
